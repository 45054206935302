import * as React from 'react'
import Layout from '../layouts/Layout';
import Seo from '../config/seo';
import BaseLayout from '../layouts/BaseLayout';

const NotFoundPage = () => (
  <BaseLayout>
    <Layout>
      <Seo title="Not Found" />
      <section className='not__found'>
        <h1>404</h1>
        <p>Not Found.</p>
      </section>
    </Layout>
  </BaseLayout>
)

export default NotFoundPage
